

  @font-face {
    font-family: "Light";   /*Can be any text*/
    src: local("proba_pro_regular"),
      url("./fonts/proba_pro_regular.ttf") format("truetype");
      font-weight: bold;
  }

  .header {    
    font-size: 30px;    
  }

  @media screen and (max-width: 480px) {
    .header {
      font-size: 12px;
    }
}