.article {
    height: 100%;
    position: relative;
    overflow: hidden;
}

.picture {
    object-fit: cover;
    width: 100%;
    height: 100%;
}

.mainfoto {
    font-size: 60px;
    color: white;
    text-align: left;
    margin-top: 20vh;
    height: fit-content;
    text-shadow: 0 0 25px black;
    padding-left: 8%;
}

.description {
  font-size: 20px;
  color: white;
  text-align: left;     
  text-shadow: 0 0 15px black;
  padding-left: 8%;
}

@media screen and (max-width: 480px) {
  .description {
    font-size: 20px;
    color: white;
    text-align: left;     
    text-shadow: 0 0 15px black;
    padding-left: 8%;
  }

  .mobile-card-spacing:not(:last-child) {
    margin-right: 20px; /* Adjust the value as needed */
  }

  .mainfoto {
    font-size: 30px;
    color: white;
    text-align: left;
    margin-top: 7vh;
    height: fit-content;
    text-shadow: 0 0 25px black;
    padding-left: 8%;
  }
}


.otherpagestext {
    font-size: 20px;
    color: rgb(32, 32, 32);
    box-sizing: border-box;
    padding: 15px;    
}
.otherpages {
    font-size: 55px;
    color: rgb(32, 32, 32);
    box-sizing: border-box;
    padding: 10px;
    
}




.otherfoto{
    font-size: 80px;
    color: white;
    text-align: center;
    /* position: absolute; */
    /* top: 0;
    bottom: 0;
    left: 0;
    right: 0; */
    margin-top: 35vh;
    height: fit-content;
    text-shadow: 0 0 25px black;
}

.content {
    box-sizing: border-box;
    padding: 20px;
}

.fonttest {
    font-family: "Light";
}
.grad {
    background-image: linear-gradient(180deg, rgb(41, 70, 91, 1), white );
  }

.mxAuto {
  margin-left: auto;
  margin-right: auto;
}

.invertedImg {
    filter: invert(100%);
}
